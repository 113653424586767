const faqPanels = [...document.querySelectorAll('.faq')];
let faqExpandAll = document.querySelector('.expand-all-faqs');

if(faqPanels){
  faqPanels.forEach(panel => {
    panel.querySelector('.open-faqs').addEventListener('click', (e) => {
      e.preventDefault();
      const faqAnswer = panel.querySelector('.faq-panel');
      faqAnswer.classList.toggle('open-faq');
    });
  })
}

if(faqPanels && faqExpandAll){
  faqExpandAll.addEventListener('click', (e) => {
    e.preventDefault();
    let text = faqExpandAll.innerText;
    const faqAnswer = [...document.querySelectorAll('.faq-panel')];
    faqAnswer.forEach(answer => {
      answer.classList.toggle('open-faq');
    })
    if (text === 'Expand all Panels') {
      faqExpandAll.innerText = 'Collapse all Panels';
    }
    else {
      faqExpandAll.innerText = 'Expand all Panels';
    }
  });
}
